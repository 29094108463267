<template>
  <div class="checkbox-input">
    <input
      id="agreement"
      type="checkbox"
      name="agreement"
      class="checkbox"
      :checked="value"
      @change="$emit('input', $event.target.checked)"
    />
    <label class="label" for="agreement">
      <slot />
    </label>
    <InputError v-if="error" :error-txt="errorTxt" />
  </div>
</template>

<script>
import InputError from '@/elements/InputError.vue';

export default {
  components: {
    InputError,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    errorTxt: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="sass" scoped>
.checkbox-input
  display: flex
  position: relative

.checkbox
  position: absolute
  opacity: 0
  top: 0
  left: 0

.checkbox + .label
  position: relative
  display: flex
  align-items: center
  padding-left: 36px
  font-weight: 400
  font-size: 1.4rem
  line-height: 24px
  color: $text-primary-color
  text-decoration: underline
  cursor: pointer

.checkbox + .label:before
  position: absolute
  content: ''
  width: 24px
  height: 24px
  left: 0
  border: 1px solid $black
  background-color: transparent

.checkbox:checked + .label:before
  background-color: $black
  border: 1px solid $black
  background-image: url('/images/check.svg')
  background-position: center
</style>
