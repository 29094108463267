import { required, minLength, helpers } from 'vuelidate/lib/validators';

const alpha = helpers.regex('alpha', /^[A-Za-z-' ]+$/);

export default {
  validations: {
    lastName: {
      required,
      minLength: minLength(2),
      alpha,
    },
  },
  computed: {
    isLastNameError() {
      return (
        (this.$v.lastName.$dirty && this.$v.lastName.$invalid) ||
        !this.$v.lastName.alpha
      );
    },
    lastNameErrorText() {
      if (!this.$v.lastName.required) {
        return 'The field is required';
      }
      if (!this.$v.lastName.alpha) {
        return 'Last name must contains only latin characters';
      }
      if (!this.$v.lastName.minLength) {
        return 'Last name must be at least 2 characters';
      }
    },
  },
};
