import firstNameValidation from '@/mixins/validation/fields/firstName';
import lastNameValidation from '@/mixins/validation/fields/lastName';
import emailValidation from '@/mixins/validation/fields/email';
import referralIdValidation from '@/mixins/validation/fields/referralId';
import passwordValidation from '@/mixins/validation/fields/passwords/password';
import confirmPasswordValidation from '@/mixins/validation/fields/passwords/confirmPassword';
import agreementValidation from '@/mixins/validation/fields/agreement';
import validationsMethods from '@/mixins/validation/common/validationMethods';

export default {
  mixins: [
    firstNameValidation,
    lastNameValidation,
    emailValidation,
    referralIdValidation,
    passwordValidation,
    confirmPasswordValidation,
    agreementValidation,
    validationsMethods,
  ],
  validations: {
    ...firstNameValidation.validations,
    ...lastNameValidation.validations,
    ...emailValidation.validations,
    ...referralIdValidation.validations,
    ...passwordValidation.validations,
    ...confirmPasswordValidation.validations,
    ...agreementValidation.validations,
  },
};
