<template>
  <form class="signup-form" novalidate @submit.prevent>
    <h1 class="signup-form__title">Sign up</h1>
    <div class="signup-form__subtitle text">
      We need you to help us with some basic information for your account
      creation.
    </div>
    <div class="signup-form__items">
      <div class="signup-form__item">
        <LabelField
          id="firstname"
          class="signup-form__label"
          title="First name"
        />
        <TextField
          id="firstname"
          v-model.trim="firstName"
          name="firstname"
          required
          class="signup-form__input"
          type="text"
          :error="isFirstNameError"
          :error-txt="firstNameErrorText"
          tooltip-text=""
        />
      </div>
      <div class="signup-form__item">
        <LabelField
          id="lastname"
          class="signup-form__label"
          title="Last name"
        />
        <TextField
          id="lastname"
          v-model.trim="lastName"
          name="lastname"
          required
          class="signup-form__input"
          type="text"
          :error="isLastNameError"
          :error-txt="lastNameErrorText"
          tooltip-text=""
        />
      </div>
      <div class="signup-form__item">
        <LabelField id="email" class="signup-form__label" title="E-Mail" />
        <TextField
          id="email"
          v-model.trim="email"
          name="email"
          required
          class="auth-form__input"
          type="text"
          :error="isEmailError"
          :error-txt="emailErrorText"
          tooltip-text=""
          @focus="resetServerError('email')"
        />
      </div>
      <div class="signup-form__item">
        <LabelField
          id="referral-id"
          class="signup-form__label"
          title="Referral ID"
        />
        <TextField
          id="referral-id"
          v-model.trim="referralId"
          name="referral-id"
          required
          class="signup-form__input"
          type="text"
          :error="isReferralIdError"
          :error-txt="referralIdErrorText"
          tooltip-text="Paste the code or link from the invitation"
          @focus="resetServerError('referral_code')"
        />
      </div>
      <div class="signup-form__item">
        <LabelField id="password" class="signup-form__label" title="Password" />
        <TextField
          id="password"
          v-model.trim="password"
          name="password"
          required
          class="signup-form__input"
          type="password"
          is-password
          :password-strength="passwordStrength"
          :error="isPasswordError"
          :error-txt="passwordErrorText"
          tooltip-text="Password must be at least 8 characters with 1 upper case letter and 1 number."
          @focus="resetServerError('password')"
        />
      </div>
      <div class="signup-form__item">
        <LabelField
          id="confirm-password"
          class="signup-form__label"
          title="Confirm password"
        />
        <TextField
          id="confirm-password"
          v-model.trim="confirmPassword"
          name="confirm-password"
          required
          class="signup-form__input"
          type="password"
          is-password
          :password-strength="confirmPasswordStrength"
          :error="isConfirmPasswordError"
          :error-txt="confirmPasswordErrorText"
          tooltip-text="Password must be at least 8 characters with 1 upper case letter and 1 number."
        />
      </div>
    </div>
    <div class="signup-form__controls">
      <CheckboxField
        v-model="$v.agreement.$model"
        :error="isAgreementError"
        :error-txt="agreementErrorText"
      >
        <a href="https://google.com" target="_blank"
          >I agree to the terms of the user agreement</a
        >
      </CheckboxField>
      <ButtonBase
        class="signup-form__btn"
        :loading="nextLoader"
        @click="signupHandler"
        >Next</ButtonBase
      >
    </div>
  </form>
</template>

<script>
import signupValidation from '@/mixins/validation/forms/signup.js';
import CheckboxField from '@/elements/CheckboxField.vue';
import LabelField from '@/elements/LabelField';

export default {
  components: {
    LabelField,
    CheckboxField,
  },
  mixins: [signupValidation],
  data() {
    return {
      nextLoader: false,
      firstName: '',
      lastName: '',
      email: '',
      referralId: '',
      password: '',
      confirmPassword: '',
      agreement: false,
      loadingSubmitBtn: false,
    };
  },
  computed: {
    signupData() {
      const data = {
        first_name: this.firstName,
        last_name: this.lastName,
        email: this.email,
        password: this.password,
        password_confirmation: this.confirmPassword,
      };

      if (!!this.referralId) {
        data.referral_code = this.referralId;
      }

      return data;
    },
  },
  created() {
    const refererCode = this.$route.query.referral_code;
    if (!!refererCode) {
      this.referralId = refererCode;
    }
  },
  methods: {
    async signupHandler() {
      if (this.isInvalidForm) {
        this.showValidationErrors();
        return;
      }
      this.nextLoader = true;
      await this.$store.dispatch('signup', this.signupData);
      this.nextLoader = false;
    },
  },
};
</script>

<style lang="sass" scoped>
.signup-form__subtitle
  margin-top: 12px
  margin-bottom: 64px
  @include ms
    margin-top: 4px
    margin-bottom: 40px

.signup-form__items
  display: grid
  grid-template-columns: 1fr 1fr
  gap: 24px 40px
  @include ms
    grid-template-columns: 1fr

.signup-form__controls
  display: flex
  align-items: center
  justify-content: space-between
  margin-top: 72px
  @include ms
    align-items: flex-start
    flex-direction: column
    gap: 40px
    margin-top: 40px

.signup-form__btn
  @include ms
    align-self: center
</style>
